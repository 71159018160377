import * as React from "react"
import { motion, AnimatePresence } from "framer-motion";

import Layout from "../components/layout"
import Warehouses from "../components/warehouses"

import estrella from "../images/estrella-bca.svg"

const MaritimoPage = () => {
	const [expoExpand, setExpoExpand] = React.useState(false)
	const [impoExpand, setImpoExpand] = React.useState(false)

  return (
  <Layout
	seccion="maritimo"	
	title="Marítimo"	
	linkTo="experienciaContainer"
	>		
		<div id="experienciaContainer" className="raicoContainer">
			<div className="row">
				<div id="exportacionMar" className="col-12 col-sm-6 pr-0 ">
					<div className={"expTxt bgLightBlue "+(expoExpand ? 'serviceExpand' : '')}>
						
						<div id="" className="expDesc col-sm-11"
									data-aos='fade-up'
									data-aos-duration="600" data-aos-once="true">	
							
							<div className="txtDesc">
								<h2>Servicio de Carga Marítima de<br/>Exportación</h2>
							</div>

							<p>Servicio de transporte marítimo LCL / FCL.</p>
							
							
				
							{!expoExpand && 
							<div className={"expand text-white "+(expoExpand ? 'invisible' : '')}
								onClick={() => setExpoExpand(!expoExpand)}
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
								  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
								</svg>
								CONOCER MÁS
							</div>
							}

							<AnimatePresence initial={false}>
								{expoExpand && (
								  <motion.section
									key="content"
									initial="collapsed"
									animate="open"
									exit="collapsed"
									variants={{
									  open: { opacity: 1, height: "auto" },
									  collapsed: { opacity: 0, height: 0 }
									}}
									transition={{ duration: 1.1, ease: [0.04, 0.62, 0.23, 0.98] }}
								  >
									<div className="infoExpanded">
										<p>Nuestra fortaleza radica en el asesoramiento integral para la elaboración de los documentos de exportación, el pick- up en todo el territorio argentino y la consolidación de carga en los depósitos de nuestros agentes.<br/><br/>
										Aseguramos el arribo con éxito de la carga al puerto indicado en tiempo y forma según sus requerimientos.</p>
							
										<ul className="beneficios"
										style={{marginBottom: '20px'}}>
											<li><img src={estrella} alt="" title="" /><img src={estrella} alt="" title="" /><img src={estrella} alt="" title="" /></li>
										</ul>
										<ul className="beneficios">
											<li><img src={estrella} alt="" title="" />Consolidación de contenedores en depósito fiscal.</li>
											<li><img src={estrella} alt="" title="" />Asesoramiento sobre la elaboración de documentos de exportación.</li>
											<li><img src={estrella} alt="" title="" />Pick up sobre todo el territorio argentino.</li>
											<li><img src={estrella} alt="" title="" />Destinos: USA, Brasil, Latinoamérica, Europa y Asia.</li>
											<li><img src={estrella} alt="" title="" />Seguimiento de cada exportación, hasta el arribo de la carga en destino.</li>
											<li><img src={estrella} alt="" title="" />Exportaciones temporales.</li>
											<li><img src={estrella} alt="" title="" />Customer Service personalizado.</li>
											<li><img src={estrella} alt="" title="" />Asesoramiento aduanero.</li>
											<li><img src={estrella} alt="" title="" />Cotizaciones inmediatas.</li>
											<li><img src={estrella} alt="" title="" />Tablero de Control WEB para autogestión de documentación. </li>
										</ul>
										{/*
										<p className="colorLightBlue">Enviar un mail a maritimo@raiconet.com con <br/>los
										siguientes datos:</p>
										
										<ul className="email">
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Puerto de destino.</li>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>M3 o CBM.</li>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Kg Bruto.</li>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Cantidad de bultos.</li>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Medidas de los bultos.</li>
										</ul>
										*/}
									</div>
								  </motion.section>
								)}
							</AnimatePresence>
						</div>
					</div>
				</div>
				<div id="expImg" className="col-sm-6 maritimo expImg" 
								  data-sal="zoom-in"
								  data-sal-delay="0"
								  data-sal-duration="900">
					<div></div>
				</div>
			</div>			
		</div>
		<div id="importacionMar" className="raicoContainer mt-30">
			<div className="row">
				<div className="col-12 col-sm-6 pr-0 ">
					<div className={"expTxt bgLightBlue "+(impoExpand ? 'serviceExpand' : '')}>
						
						<div className="expDesc col-sm-11"
									data-aos='fade-up'
									data-aos-duration="600" data-aos-once="true">	
							
							<div className="txtDesc"><h2>Servicio de Carga Marítima de<br/>Importación</h2></div>

							<p>Servicio de transporte marítimo LCL / FCL. </p>
							
							{!impoExpand && 
							<div className={"expand text-white "+(impoExpand ? 'invisible' : '')}
								onClick={() => setImpoExpand(!impoExpand)}
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
								  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
								</svg>
								CONOCER MÁS
							</div>
							}

							<AnimatePresence initial={false}>
								{impoExpand && (
								  <motion.section
									key="content"
									initial="collapsed"
									animate="open"
									exit="collapsed"
									variants={{
									  open: { opacity: 1, height: "auto" },
									  collapsed: { opacity: 0, height: 0 }
									}}
									transition={{ duration: 1.1, ease: [0.04, 0.62, 0.23, 0.98] }}
								  >
									<div className="infoExpanded">
										<p>Desde el inicio de la compra internacional acompañamos a nuestros clientes, poniendo a su disposiciòn la experiencia y profesionalidad que avalan la calidad en nuestra gestión.
										<br/><br/>
										Nuestra fortaleza radica en una amplia gama de servicios marítimos LCL / FCL que ofrece la consolidación, el fraccionamiento, o el reacondicionamiento de la carga en los depósitos de nuestros agentes ubicados en los principales puertos del mundo.<br/>
										Nos comprometemos a cumplir en tiempo y forma  sus necesidades.</p>

										<ul className="beneficios"
										style={{marginBottom: '20px'}}>
											<li><img src={estrella} alt="" title="" /><img src={estrella} alt="" title="" /><img src={estrella} alt="" title="" /></li>
										</ul>
										<ul className="beneficios">
											<li><img src={estrella} alt="" title="" />Acuerdos con depósitos fiscales en los plazos del forzoso.</li>
											<li><img src={estrella} alt="" title="" />Carga LCL/FCL – Tarifarios exclusivos con costos preferenciales.</li>
											<li><img src={estrella} alt="" title="" />Entrega de documentación Expres.</li>
											<li><img src={estrella} alt="" title="" />Trámite de Consularizado de Documentación en Origen (Cert. Origen).</li>
											<li><img src={estrella} alt="" title="" />Opciones de rutas y salidas semanales desde cada puerto de origen.</li>
											<li><img src={estrella} alt="" title="" />Customer Service personalizado.</li>
											<li><img src={estrella} alt="" title="" />Tablero de Control WEB para autogestión de documentación.</li>
										</ul>
										
										{/*
										<p className="colorLightBlue">Enviar un mail a maritimo@raiconet.com con <br/>los
										siguientes datos:</p>
										
										<ul className="email">
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Puerto de Origen.</li>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Cbm o M3.</li>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Kg Bruto.</li>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Cantidad de bultos.</li>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Medidas de los bultos.</li>
										</ul>
										*/}
									</div>
								  </motion.section>
								)}
							</AnimatePresence>
						</div>
					</div>
				</div>
				<div id="expImg" className="col-sm-6 maritimo expImg" 
								  data-sal="zoom-in"
								  data-sal-delay="0"
								  data-sal-duration="900">
					<div></div>
				</div>
			</div>
			
		</div>
		<div id="" className="raicoContainer mt-30">
			<div className="row">
				<div className="col-12 col-sm-6">
					<div id="correo" className="tarjeta bgRed"
					  data-aos='fade-up'
					  data-aos-duration="600" 
					  data-aos-once="true"
					>
						<div className=" txtTarjeta">
							<h3>Único<br/>
							requerimiento</h3><br/>
							<span>Tener licencia de importador.</span>
						</div>
					</div>
				</div>
				<div id="cotizaciones" className="col-12 col-sm-6">
					<div className="tarjeta bgBlue"
					  data-aos='fade-up'
					  data-aos-delay="300" 
					  data-aos-duration="600" 
					  data-aos-once="true"
					>
						<div className=" txtTarjeta">
							<span>Cotizaciones</span><br/>
							<p>Enviar un mail a marítimo@raiconet.com con los
							siguientes datos:</p>
							<ul>
								<li className=""><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Puerto de Destino.</li>
								<li className=""><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Peso (m3).</li>
								<li className=""><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Cantidad de bultos (Pallets).</li>
								<li className=""><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Valor FOB (Usd).</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Warehouses />	
  </Layout>
  )
}

export default MaritimoPage
