import * as React from "react"
import WarehousePoint from "./warehousePoint"

const Warehouses = () => {

const [houstonVisible,     setHoustonVisible] = React.useState(false);
const [miamiVisible,       setMiamiVisible] = React.useState(false);
const [madridVisible,      setMadridVisible] = React.useState(false);
const [holandaVisible,      setHolandaVisible] = React.useState(false);
const [buenosAiresVisible, setBuenosAiresVisible] = React.useState(false);
const [shenzhenVisible,    setShenzhenVisible] = React.useState(false);


const hideWarehousePoints = () => {
	setHoustonVisible(false);
	setMiamiVisible(false);
	setMadridVisible(false);
	setHolandaVisible(false);
	setBuenosAiresVisible(false);
	setShenzhenVisible(false);
}
const showWarehousePoint = (point) => {
	hideWarehousePoints();
	switch(point){
		case "houston":
			setHoustonVisible(true);
		break;
		case "miami":
			console.log("miami")
			setMiamiVisible(true);
		break;
		case "buenosAires":
			setBuenosAiresVisible(true);
			console.log("buenosAires")
		break;
		case "madrid":
			setMadridVisible(true);
			console.log("madrid")
		break;
		case "holanda":
			setHolandaVisible(true);
			console.log("holanda")
		break;
		case "shenzhen":
			setShenzhenVisible(true);
			console.log("shenzhen")
		break;
	}
};

return(
	<div id="contactoContainer" className="raicoContainer">
		<div className="row">
			<div id="contactoCol" className="col-sm-3">
				<div id="contactoTxt" className="">
					<div id="contactoDesc">
						<h3>Warehouses</h3>													
					</div>
					<div id="contactoImg" className="d-block d-sm-none ">
						<div>
							<div id="contactoMap">

								<WarehousePoint 
								  key="houston"
								  id="houston"
								  pointTop="-39%"
								  pointLeft="-8%"
								  onOver={showWarehousePoint}
								  dataVisible={houstonVisible}
								>
									<h5><b>Houston</b></h5><p><b>Tejas Freight Fwdg. Inc.</b><br/>
									3340 Greens Road<br/>
									Houston, TX 77032<br/>
									Tel: 281 442-9377<br/>
									Fax: 281 442-9468<br/>
									lucy@tejasfreight.net</p>
								</WarehousePoint>
								
								<WarehousePoint 
								  key="miami"
								  id="miami"
								  pointTop="-41%"
								  pointLeft="-2%" 
								  onOver={showWarehousePoint}
								  dataVisible={miamiVisible}
								>
									<h5><b>Miami</b></h5><p><b>Ric Global Shipping LLC</b><br/>
									7848 NW 71 Street<br/>
									Miami, FL 331666<br/>
									USA<br/>
									Tel: 3057170978<br/>
									Fax: 30571709949<br/>
									Federal ID # 65-0950814</p>
								</WarehousePoint>
								
								
								<WarehousePoint 
								  key="buenosAires"
								  id="buenosAires"
								  pointTop="-22%"
								  pointLeft="-2%" 
								  onOver={showWarehousePoint}
								  dataVisible={buenosAiresVisible}
								>
									<h5><b>Buenos Aires</b></h5><p><b>RAICO S.A.</b><br/>
									Comesaña 4460 CP B17028OF<br/>
									Prov. de Bs. As., Ciudadela,<br/> Argentina<br/>
									Tel +54 11 4488-7771 y rot<br/>
									Fax: 54 11 4488-7771 int. 210<br/>
									consultas@raiconet.com</p>
								</WarehousePoint>
								
								<WarehousePoint 
								  key="madrid"
								  id="madrid"
								  pointTop="-54%"
								  pointLeft="19%" 
								  onOver={showWarehousePoint}
								  dataVisible={madridVisible}
								>
									<h5><b>Madrid</b></h5><p><b>Europa Geobox S.L</b><br/>
									Calle del Arte, 16 - Local 3<br/>
									28033 Madrid, España<br/>
									Tel: 917682129<br/>
									Fax: 917682130<br/>
									logistics@geobox.es</p>
								</WarehousePoint>
								
								{/*<WarehousePoint 
								  key="holanda"
								  id="holanda"
								  pointTop="-80%"
								  pointLeft="22%" 
								  onOver={showWarehousePoint}
								  dataVisible={holandaVisible}
								>
									<h5><b>Holanda</b></h5><p><b>IMACA B.V</b><br/>
									Grenadierweg 7<br/>
									4338 PG, Middleburg<br/>
									The Netherlands<br/>
									info@imaca.nl<br/>
									Tel: +31 (0) 113556029 <br/>
									Chamber of commerce Middleburg 22052829</p>
								</WarehousePoint>
								
								<WarehousePoint 
								  key="shenzhen"
								  id="shenzhen"
								  pointTop="-87%"
								  pointLeft="46%" 
								  onOver={showWarehousePoint}						  
								  dataVisible={shenzhenVisible}
								>
									<h5><b>Shenzhen</b></h5><p><b>360LION EXPRESSS CO., LTD.</b><br/>
									RM101, No.9<br/>
									Baihaoluoshan Road, <br/>Houjie Town, Donngguan.<br/>
									China.<br/>
									ZIP CODE: 523900<br/>
									Mobile/Wechat: 13923303060 / <br/>13510668672<br/>
									betty.li@360lion.com / <br/>anson.peng@360lion.com</p>
								</WarehousePoint>*/}
								<WarehousePoint 
								  key="shenzhen"
								  id="shenzhen"
								  pointTop="-73%"
								  pointLeft="50%" 
								  onOver={showWarehousePoint}						  
								  dataVisible={shenzhenVisible}
								>
									<h5><b>Guangzhou</b></h5><p><b>CHINA-LATIN LOGISTICS CO.,LTD </b><br/>
									Room 310, NO.37<br/>
									Huan shi xi road, <br/>Liwan district, Guangzhou city. 
									China.<br/>									
									Mobile: 86 186 6558 9180 / <br/>86 186 8022 0306<br/>
									info@chinalatinlogistics.com</p>
								</WarehousePoint>
								
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="contactoImg" className="d-sm-block d-none col-sm-9">
				<div>
					<div id="contactoMap">

						<WarehousePoint 
						  key="houston"
						  id="houston"
						  pointTop="14.5%"
						  pointLeft="9%"
						  onOver={showWarehousePoint}
						  dataVisible={houstonVisible}
						>
							<h5><b>Houston</b></h5><p><b>Tejas Freight Fwdg. Inc.</b><br/>
							3340 Greens Road<br/>
							Houston, TX 77032<br/>
							Tel: 281 442-9377<br/>
							Fax: 281 442-9468<br/>
							lucy@tejasfreight.net</p>
						</WarehousePoint>
						
						<WarehousePoint 
						  key="miami"
						  id="miami"
						  pointTop="15%"
						  pointLeft="14%" 
						  onOver={showWarehousePoint}
						  dataVisible={miamiVisible}
						>
							<h5><b>Miami</b></h5><p><b>Ric Global Shipping LLC</b><br/>
							7848 NW 71 Street<br/>
							Miami, FL 331666<br/>
							USA<br/>
							Tel: 3057170978<br/>
							Fax: 30571709949<br/>
							Federal ID # 65-0950814</p>
						</WarehousePoint>
						
						
						<WarehousePoint 
						  key="buenosAires"
						  id="buenosAires"
						  pointTop="43%"
						  pointLeft="16%" 
						  onOver={showWarehousePoint}
						  dataVisible={buenosAiresVisible}
						>
							<h5><b>Buenos Aires</b></h5><p><b>RAICO S.A.</b><br/>
							Comesaña 4460 CP B17028OF<br/>
							Prov. de Bs. As., Ciudadela,<br/> Argentina<br/>
							Tel +54 11 4488-7771 y rot<br/>
							Fax: 54 11 4488-7771 int. 210<br/>
							consultas@raiconet.com</p>
						</WarehousePoint>
						
						<WarehousePoint 
						  key="madrid"
						  id="madrid"
						  pointTop="2%"
						  pointLeft="37%" 
						  onOver={showWarehousePoint}
						  dataVisible={madridVisible}
						>
							<h5><b>Madrid</b></h5><p><b>Europa Geobox S.L</b><br/>
							Calle del Arte, 16 - Local 3<br/>
							28033 Madrid, España<br/>
							Tel: 917682129<br/>
							Fax: 917682130<br/>
							logistics@geobox.es</p>
						</WarehousePoint>
						
						{/*<WarehousePoint 
						  key="holanda"
						  id="holanda"
						  pointTop="-9%"
						  pointLeft="38.5%" 
						  onOver={showWarehousePoint}
						  dataVisible={holandaVisible}
						>
							<h5><b>Holanda</b></h5><p><b>IMACA B.V</b><br/>
							Grenadierweg 7<br/>
							4338 PG, Middleburg<br/>
							The Netherlands<br/>
							info@imaca.nl<br/>
							Tel: +31 (0) 113556029 <br/>
							Chamber of commerce Middleburg 22052829</p>
						</WarehousePoint>
						
						<WarehousePoint 
						  key="shenzhen"
						  id="shenzhen"
						  pointTop="-1%"
						  pointLeft="65%" 
						  onOver={showWarehousePoint}						  
						  dataVisible={shenzhenVisible}
						>
							<h5><b>Shenzhen</b></h5><p><b>360LION EXPRESSS CO., LTD.</b><br/>
							RM101, No.9<br/>
							Baihaoluoshan Road, <br/>Houjie Town, Donngguan.<br/>
							China.<br/>
							ZIP CODE: 523900<br/>
							Mobile/Wechat: 13923303060 / <br/>13510668672<br/>
							betty.li@360lion.com / <br/>anson.peng@360lion.com</p>
						</WarehousePoint>*/}
						
						<WarehousePoint 
						  key="shenzhen"
						  id="shenzhen"
						  pointTop="-3%"
						  pointLeft="68%" 
						  onOver={showWarehousePoint}						  
						  dataVisible={shenzhenVisible}
						>
							<h5><b>Guangzhou</b></h5><p><b>CHINA-LATIN LOGISTICS CO.,LTD </b><br/>
							Room 310, NO.37<br/>
							Huan shi xi road, <br/>Liwan district, Guangzhou city. 
							China.<br/>									
							Mobile: 86 186 6558 9180 / <br/>86 186 8022 0306<br/>
							info@chinalatinlogistics.com</p>
						</WarehousePoint>
						
					</div>
				</div>
			</div>
		</div>
	</div>	
  )
}
export default Warehouses


