import * as React from "react"
import PropTypes from "prop-types"

const WarehousePoint = ({id, children, pointTop, pointLeft, onOver, dataVisible }) => {
	//const [dataVisible, setDataVisible] = React.useState(false)
	
	return(<>
		<div className="warehousePoint"
			style={{
				position: 'absolute',
				top: pointTop,
				left:  pointLeft }}>
			<div className="tooltip-inner"
				style={{
					visibility: dataVisible ? 'visible' : 'hidden'
				}}>
				{children}					
			</div>
			<div className="arrowContainer">
				<svg 
					onMouseOver={() => onOver(id) }
					
xmlns="http://www.w3.org/2000/svg" x="580" y="387"  fill="currentColor" className={"bi bi-circle location "+(dataVisible ? 'visible' : '') } viewBox="0 0 16 16">
	{/*<path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path> */}
	<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
				</svg>
			</div>
		</div>
	</>)
}
  

WarehousePoint.propTypes = {
  pointTop: PropTypes.string,
  pointLeft: PropTypes.string,
}
export default WarehousePoint
