/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import AOS from 'aos';
import 'aos/dist/aos.css';

import '../styles/bootstrap.min.css';
import '../styles/bootstrap-reboot.min.css';
import '../styles/styles.css';

import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"


const Layout = ({ children, title, subTitle, seccion, linkTo }) => {
  
  React.useEffect(() => {
	  AOS.init({
		  once: true
	  });
  }, []);

  return (
    <>      
		<Seo 
			title={title + " " +subTitle}
		></Seo>
		<div className={"container-fluid "+seccion}>
			<Header
				title={title}
				subTitle={subTitle}
				seccion={seccion}
				linkTo={linkTo}
			/>
				{children}
			<Footer />
		</div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
Layout.defaultProps = {
  title: ``,
  subTitle: ``,
  seccion: ``,
  linkTo: ``
}
export default Layout
